import { createStore } from 'vuex'

const store = createStore({
  state() {
    return {
      isLoggedIn: false,
      group: null,
    };
  },

  mutations: {
    setGroup(state, group) {
      state.group = group
    },
  },
  
  actions: {
    async fetchGroup({ commit }, groupId) {
      try {
        const response = await this.$http.getGroups(groupId);
        commit('setGroup', response.data);
      } catch (error) {
        console.error('获取群组详情时出错：', error);
      }
    },
  },

  getters: {
    getGroup: state => state.group,
  },
})

export default store
<template>
<div class="release-container">
  <nut-navbar @on-click-back="onClickBack" title="发布群" left-show fixed></nut-navbar>

  <div class="release-group">
    <nut-form ref="releaseForm" :model-value="formData" :rules="rules">
      <nut-form-item label="群名称" prop="name">
        <input class="nut-input-text" v-model="formData.name" placeholder="请输入群名称"
         type="text" show-error-line show-error-message/>
      </nut-form-item>

      <nut-form-item label="入群规则" prop="rule">
        <nut-textarea class="nut-input-text" v-model="formData.rule" placeholder="请输入群规则"
         limit-show max-length="100" rows="6" />
      </nut-form-item>

      <nut-form-item label="群介绍" prop="intro">
        <nut-textarea class="nut-input-text" v-model="formData.intro" placeholder="请输入群介绍"
         limit-show max-length="100" rows="6" />
      </nut-form-item>

      <nut-form-item label="价格" prop="price">
        <input class="nut-input-text" v-model="formData.price" placeholder="请输入价格"
         type="text" show-error-line show-error-message />
      </nut-form-item>

      <nut-form-item prop="category">
        <nut-cell class="modify-picker" title="请选择分类" :desc="formData.categoryDesc" @click="toggleCategorPicker"></nut-cell>
        <nut-picker v-model:visible="formData.categoryShow" :columns="formData.categoryColumns"
        title="分类选择" @confirm="formData.categoryConfirm"  @change="formData.categoryChange">
        </nut-picker>
      </nut-form-item>
      
      <nut-form-item prop="city">
        <nut-cell class="modify-picker" title="请选择城市" :desc="formData.cityDesc" @click="toggleCityPicker"></nut-cell>
        <nut-picker v-model:visible="formData.cityShow" v-model="formData.selectedCityValue"
        :columns="formData.cityColumns" title="城市选择" @confirm="formData.cityConfirm" 
        @change="formData.cityChange">
        </nut-picker>
      </nut-form-item>

      <nut-form-item label="上传群头像" prop="picture">
        <nut-uploader :url="uploadUrl" accept="image/jpeg" name="file" maximum="1"
         v-model:file-list="formData.defaultFileList"
         :header="headers"
         @success="onAvatarSuccess">
        </nut-uploader>
      </nut-form-item>

      <nut-form-item label="上传群主&#10;/&#10;群二维码" prop="qr_code">
        <nut-uploader :url="uploadUrl" accept="image/jpeg" name="file" maximum="1"
         v-model:file-list="formData.qrcodeFileList"
         :header="headers"
         @success="onQrcodeSuccess">
        </nut-uploader>
      </nut-form-item>

      <nut-button @click="onReleaseSubmit" block>发布</nut-button>
    </nut-form>
  </div>
</div>
</template>

<script type="text/ecmascript-6">
import { Toast } from '@nutui/nutui'
import cityMap from '../data/city.json'

export default {
  name: "ReleasePage",
  data() {
    const nameLengthValidator = (val) => val && val.length >= 2
    const priceValidator = (val) => /^\d+(\.\d{1,2})?$/.test(val)
    const avatarValidator = (val) => val && val.length > 0
    const qrcodeValidator = (val) => val && val.length > 0

    return {
      formData: {
        name: '',
        rule: '',
        intro: '',
        price: '',

        // 分类、城市参数设置
        category_id: '',
        categoryShow: false,
        categoryDesc: '',
        categoryColumns: [],
        categoryConfirm: this.handleCategoryConfirm,
        categoryChange: this.handleCategoryChange,

        city: '',
        cityShow: false,
        cityDesc: '',
        selectedCityValue: ['101210100'],
        cityConfirm: this.handleCityConfirm,
        cityChange: this.handleCityChange,

        uid: '',
        defaultFileList: [{
          name: 'default-avatar.jpg',
          url: 'http://120.27.109.202:8000/media/img/default-avatar.jpg',
          status: 'success',
          message: '上传成功',
          type: 'image'
        }],
        qr_code: []
      },
      uploadUrl: 'http://120.27.109.202:8000/upload',
      headers: {
        "Authorization": "JWT " + this.$auth.token
      },

      rules: {
        name: [
          { required: true, message: '请输入群名称' },
          { validator: nameLengthValidator, message: '群名称至少两个字符' }
        ],
        rule: [
          { required: true, message: '请输入群规则' },
        ],
        intro: [
          { required: true, message: '请输入群介绍' },
        ],
        price: [
          { required: true, message: '请输入价格' },
          { validator: priceValidator, message: '必须输入数字' }
        ],
        category: [
          { required: true, message: '请选择分类' },
        ],
        city: [
          { required: true, message: '请选择城市' },
        ],
        picture: [
          { required: true, message: '请上传群头像' },
          { validator: avatarValidator, message: '请上传至少一张头像图片' }
        ],
        qr_code: [
          { required: true, message: '请上传群头像' },
          { validator: qrcodeValidator, message: '请上传至少一张头像图片' }
        ]
      }
    }
  },

  components: {}, 

  mounted() {
    this.getCategories()
    this.getCityList()
    this.formData.uid = this.$auth.user ? this.$auth.user.uid : ''
  },

  methods: {
    onClickBack() {
      this.$router.back()
    },

    toggleCategorPicker() {
      this.formData.categoryShow = !this.formData.categoryShow
    },

    handleCategoryConfirm({ selectedValue, selectedOptions }) {
      if (Array.isArray(selectedValue)) {
        const selectedOption = selectedOptions[0].text;
      
        // console.log('Selected Category ID:', selectedValueId);  
        // console.log('Selected Category Name:', selectedOption.text);
        this.formData.category_name = selectedOption

        this.formData.categoryDesc = selectedOptions.map(option => option.text).join(',');
        this.formData.category = selectedValue.join(',');
      }
    },

    handleCategoryChange({ selectedOptions }) {
      this.formData.categoryDesc = selectedOptions.map(option => option.text).join(',');
    },

    // 获取分类列表
    getCategories() {
      this.$http.getCategoryList().then(res => {
        this.formData.categoryColumns = res.data.map(category => {
          return {
            text: category.name,
            value: category.id
          }
        })
      }).catch(error => {
        console.error('获取分类数据时出错：', error)
      })
    },

    toggleCityPicker() {
      this.formData.cityShow = !this.formData.cityShow
    },

    handleCityConfirm({ selectedValue, selectedOptions }) {
      if (Array.isArray(selectedValue)) {
        this.formData.cityDesc = selectedOptions.map(option => option.text).join(',');
        this.formData.city = selectedOptions.map(option => option.text).join(',');
      }
    },

    handleCityChange({ selectedOptions }) {
      this.formData.cityDesc = selectedOptions.map(option => option.text).join(',');
    },

    // 获取城市数据
    getCityList() {
      // 初始化 cityColumns 数组
      let cityColumns = []
      cityMap.cityList.forEach(group => {
        // 遍历每个城市组
        group.list.forEach(city => {
          // 将符合要求的格式的对象推入 cityColumns 数组
          cityColumns.push({
            text: city.name,
            value: city.id
          });
        });
      });

      this.formData.cityColumns = cityColumns;
    },

    onUploadSuccess(res, type) {
      try {
        const responseData = JSON.parse(res.responseText);
        if (responseData && responseData.image) {
          // 根据 type 参数确定更新哪个字段
          const fieldName = type === 'avatar' ? 'picture' : 'qr_code';
          // 将图片链接分配给相应的字段
          this.formData[fieldName] = responseData.image;
          // 根据上传类型提供反馈
          const successMessage = type === 'avatar' ? '头像上传成功' : '二维码上传成功';
          Toast.success(successMessage);
        } else {
          throw new Error('无效的响应格式');
        }
      } catch (error) {
        console.error(`Error in on${type.charAt(0).toUpperCase() + type.slice(1)}Success:`, error);
        const failMessage = type === 'picOfqrcode' ? '头像上传失败，请重试' : '二维码上传失败，请重试';
        Toast.fail(failMessage);
      }
    },

    onAvatarSuccess(res) {
      this.onUploadSuccess(res, 'avatar');
    },

    onQrcodeSuccess(res) {
      this.onUploadSuccess(res, 'qrcode');
    },

    onReleaseSubmit() {
      this.$refs.releaseForm.validate().then((valid) => {
        if (valid) {
          const formData = this.formData;
          const payload = {
            name: formData.name,
            join_rule: formData.rule,
            intro: formData.intro,
            price: formData.price,
            category: formData.category,
            category_name: formData.categoryDesc,
            city: formData.city,
            picture: formData.picture ? formData.picture : 'http://120.27.109.202:8000/media/img/default-avatar.jpg',
            qr_code: formData.qr_code,
            uid: formData.uid
          };

          console.log(payload)

          this.$http.addGroups(payload).then(() => {
            console.log('发布成功');
            this.$router.replace('/');
          }).catch((err) => {
            console.error('发布过程中出错:', err);
            Toast.fail('发布失败，请重试！');
          });
        } else {
          console.log('发布失败，表单验证未通过');
        }
      }).catch((err) => {
        console.error('验证过程中出错:', err);
        console.log('发布失败');
      });
    }

  }
}
</script>

<style>
@import '/src/styles/init.css';

</style>

<style scoped lang="scss">
.release-container {
  .release-group {
    .nut-cell .modify-picker {
      padding-left: 0;
    }
    // .nut-cell:after {
    //   border-bottom: none;
    // }
    .nut-button {
      width: 315px;
      height: 46px;
      background: #51CBDA;
      font-size: 18px;
      font-weight: 700;
      color: #fff;
      border: 0;
      margin: 90px 30px 20px;
    }
  }
} 
</style>
<template>
  <router-view></router-view>
  <nut-tabbar bottom active-color="#51CBDA" v-if="showTabbar">
    <nut-tabbar-item tab-title="加群" icon="home" to="/"></nut-tabbar-item>
    <nut-tabbar-item tab-title="我的" icon="my" to="/my"></nut-tabbar-item>
  </nut-tabbar>
</template>

<script>
import { Tabbar, TabbarItem } from "@nutui/nutui";
export default {
  name: "App",
  data() {
    return {}
  },
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
  computed: {
    showTabbar() {
      const name = this.$route.name;
      if(name === 'home' || name === 'my' || name === 'homeWithCity'){
        return true;
      }else{
        return false;
      }
    }
  }
}
</script>

<style lang="scss">
@import "styles/init.css";

</style>


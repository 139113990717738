import { createRouter, createWebHistory } from 'vue-router'
import auth from './utils/auth'
import Login from './components/Login.vue'
import Home from './components/Home.vue'
import My from './components/My.vue'
import Group from './components/Group.vue'
import Release from './components/Release.vue'
import City from './components/City.vue'


const routes = [
  {
    path: '/',
    component: Home,
    name: 'home'
  },
  {
    path: '/my', component: My, name: 'my',
  },
  { 
    path: '/group/:id', component: Group, name: 'group',
    meta: { requireAuth: true } 
  },
  { 
    path: '/release', component: Release, name: 'release',
    meta: { requireAuth: true } 
  },
  { 
    path: '/login', component: Login, name: 'login'
  },
  { 
    path: '/city', component: City, name: 'city'
  },
  { 
    path: '/:cityname', component: Home, name: 'homeWithCity', props: true
  },
]

const history = createWebHistory(process.env.BASE_URL)
const router = createRouter({
  history,
  routes
}
)

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth && !auth.is_authed) {
    next({
      "name": "login",
      "query": { "from": to.path }
    })
  } else {
    next()
  }
})

router.onError((error) => {
  console.error('Routing Error:', error)
})

export default router
<template>
<div class="city-container">
  <nut-navbar @on-click-back="onClickBack" title="选择城市" left-show fixed></nut-navbar>
  <nut-elevator :index-list="cityList" :is-sticky="true" :height="667" @click-item="clickItem" @click-index="clickIndex"></nut-elevator>
</div>
</template>

<script type="text/ecmascript-6">
import cityMap from '../data/city.json'

export default {
  name: "CityPage",
  data() {
    return {
      cityList: [
        { 
          title: "热门", 
          list: [
            { id: 0, name: "全国" }
          ] 
        },
        ...cityMap.cityList
      ]
    }
  },
  components: {},

  methods: {
    onClickBack() {
      this.$router.push('/')
    },

    clickIndex(key) {
      console.log(key)
    },

    clickItem(key, item) {
      const cityName = item.name;
      if (cityName === "全国") {
        this.$router.push({ name: 'homeWithCity', params: { cityname: "全国" } });
      } else {
        this.$router.push({ name: 'homeWithCity', params: { cityname: cityName } });
      }
    }
  },
}
</script>

<style scoped lang="scss">
</style>
import { createApp } from 'vue'
import App from './App.vue'
// 导入京东所有组件
import NutUI from "@nutui/nutui"
import "@nutui/nutui/dist/style.css"
import router from './router'
import store from './store'
import auth from './utils/auth'
import http from './utils/http'


// 创建应用，使用 NutUI 和 router 插件
const app = createApp(App)
app.use(store)
app.use(router)
app.use(NutUI)
app.config.globalProperties.$auth = auth
app.config.globalProperties.$http = http
app.mount('#app')
<template>
  <div class="qun-container">
    <nut-navbar @on-click-back="onClickBack" :title="group.name" left-show fixed>
      <template #right> 
        <nut-icon class="right" name="share"></nut-icon>
      </template>
    </nut-navbar>

    <div class="qun-group">
      <img :src="group.qr_code" class="qrcode" @click="qrcodeShow = true">
      <p class="code-text">点击查看二维码</p>
      <nut-overlay v-model:visible="qrcodeShow">
        <div class="wrapper">
          <div class="content"> 
            <p class="qrcode-text">长按图片，识别二维码</p>
            <img :src="group.qr_code" class="qrcode-img" >
          </div>
        </div>
      </nut-overlay>
    </div>

    <div class="qun-item">
      <div class="qun-rule">
        <h3>入群规则</h3>
        <p>{{ group.join_rule }}</p>
      </div>
      <div class="qun-describe">
        <h3>群介绍</h3>
        <p>{{ group.intro }}</p>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">

export default {
  name: "GroupPage",
  components: {},
  data() {
    return {
      qrcodeShow: false,
      group: []
    }
  },

  mounted() {
    this.getGroup()
  },

  methods: {
    onClickBack() {
      const tabIndex = this.$route.query.tabIndex || 0;
      const city = this.$route.query.city || '全国';
      this.$router.push({ name: 'home', query: {city, tabIndex } });
      console.log('回传的数据', city, tabIndex )
    },

    // 获取群详情数据
    getGroup() {
      const groupId = this.$route.params.id;
      if (groupId) {
        this.$http.getGroups(groupId).then(res => {
          this.group = res.data
          console.log('获取群组详情成功', res);
        }).catch(error => {
          console.error('获取群组详情时出错', error);
        });
      } else {
        console.error('群组ID未定义');
      }
    }
  }
}
</script>

<style>
@import '/src/styles/init.css';
</style>

<style scoped lang="scss">
.qun-container {
  .nut-navbar {
    padding: 0 12px;
    margin-bottom: 0;
  }
  .qun-group {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px 130px;
    .qrcode {
      width: 215px;
      height: 215px;
    }
    .code-text {
      margin-top: 10px;
      color: #487cff;
    }
    .wrapper {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      .content {
        display: flex;
        width: 340px;
        height: 380px;
        background: #fff;
        border-radius: 10px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .qrcode-text {
          margin-bottom: 20px;
        }
        .qrcode-img {
          width: 260px;
          height: 260px;
        }
      }
    }
  }
  .qun-item {
    display: flex;
    height: 100%;
    padding: 0 20px;
    flex-direction: column;
    .qun-rule {
      display: flex;
      padding: 15px;
      background: #fffbda;  
      border: 1px solid #ffecab;
      border-radius: 10px;
      flex-direction: column;
      margin-bottom: 10px;
    }
    .qun-describe {
      display: flex;
      padding: 15px;
      background: #ffe0ff;  
      border: 1px solid #eb98e0;
      border-radius: 10px;
      flex-direction: column;
    }
  }
}
</style>
<template>
  <div class="home-container">
    <div class="header-container">
      <!--头部-->
      <nut-row>
        <nut-col :span="5">
          <!--定位-->
          <router-link to="/city">
            <div class="flex-content city">
              <p v-if="currentCity">{{ currentCity }}</p>
              <nut-icon name="down-arrow" color="white"></nut-icon>
            </div>
          </router-link>
        </nut-col>
  
        <nut-col :span="19">
          <!--搜索-->
          <div class="flex-content flex-content-light">
            <nut-searchbar class="nut-searchbar" v-model="searchValue" placeholder="搜一搜" background="#51CBDA" input-background="#fff" clearable>
              <template v-slot:leftin>
                <nut-icon size="14" name="search2"></nut-icon>
              </template>
            </nut-searchbar>
          </div>
        </nut-col>
      </nut-row>
      <div class="carousel-img">
        <!--轮播图-->
        <nut-swiper :init-page="carouselChart" :pagination-visible="false" height="150">
          <nut-swiper-item>
            <img src="../image/1.png" />
          </nut-swiper-item>
        </nut-swiper>
      </div>
    </div>
    <div class="main-container" v-if="categories.length">
      <!--内容-->
      <nut-tabs v-model="tabIndex" @change="onTabChange" name="tabbarvalue" title-scroll title-gutter="10" background="white" color="#51CBDA">
        <!--分类-->
        <nut-tabpane v-for="(category, index) in categories" :key="index" :title="category.name">
          <div v-show="isNoGroups" class="no-group-message">
            该分类中没有群组数据
          </div>
          <div class="qun-list" v-for="group in filteredGroups" :key="group.id">
            <img :src="group.picture" class="qun-img">
            <div class="qun-info">
              <div class="qun-title">{{group.name}}</div>
              <div class="release-time">
                <nut-icon name="clock" size="18" class="clock-icon"></nut-icon>
                <p class="clock-text">{{group.release_time}}</p>
              </div>
            </div> 
            <transition name="moveR">
              <nut-button style="border: 1px solid #51CBDA;" class="clock-btn" @click="joinGroup(group.id)">
                加群
              </nut-button>
            </transition> 
          </div>
        </nut-tabpane>
      </nut-tabs>
    </div>
  
    <!--悬浮导航-->
    <router-link to="/release">
      <transition name="moveR">
        <nut-drag direction="y" :attract="true" :style="{right:'0px', bottom:'120px'}">
          <nut-button type="primary" class="release-btn">
            <p class="release-text">发布</p>
          </nut-button>
        </nut-drag>
      </transition>
    </router-link>
  </div>
</template>
  
<script type="text/ecmascript-6">

export default {
  name: "HomePage",
  data() {
    return {
      currentCity: '全国',  // 当前城市
      searchValue: '',  // 搜索
      carouselChart: 2,  // 轮播

      tabIndex: 0,  // 默认选中第一个分类

      categories: [],  // 分类
      groups: [],  // 群组

      isFetching: false,  // 防止重复调用
    }
  },
  
  components: {},
  
  created() {
    // 获取城市和检查分类
    this.setCityAndCheckCategories(this.$route.query.city)
    // 获取分类列表
    this.fetchCategories()
  },

  watch: {
    '$route.query': {
      handler(newQuery) {
        // 监听路由变化，更新城市和分类
        this.currentCity = newQuery.city || '全国';
        this.tabIndex = parseInt(newQuery.tabIndex, 10) || 0;

        // 如果分类已加载，则重新获取群组列表  
        if (this.categories.length > 0) {
          this.fetchGroups(this.currentCity, this.categories[this.tabIndex].name);
        }
      },
      immediate: true, // 组件创建时立即执行一次
    }        
  },
  
  methods: {
    // 更新城市并获取群组列表
    setCityAndCheckCategories(cityname) {
      this.currentCity = cityname || this.$route.params.cityname || '全国'

      if (this.categories.length > 0) {
        this.fetchGroups(this.currentCity, this.categories[this.tabIndex].name);
      }
    },

    onTabChange() {
      // 切换分类时，重新获取群组列表
      if (this.categories.length > 0) {
        const categoryName = this.categories[this.tabIndex].name || ''
        this.fetchGroups(this.currentCity, categoryName)
      } else {
        console.warn('分类数据尚未加载完成，无法切换分类')
      }
    },

    // 获取分类列表
    fetchCategories() {
      this.$http.getCategoryList().then(res => {  
        this.categories = res.data || [];
  
        if (this.categories.length > 0) {
          this.fetchGroups(this.currentCity, this.categories[this.tabIndex].name);
        } else {
          console.warn('未获取到分类数据');
        }
      }).catch(error => {
        console.error('分类加载错误:', error)
      })
    },
  
    // 获取群组列表
    fetchGroups(city, category = "") {
      if (this.isFetching) return
      this.isFetching = true

      this.$http.getGroupList(city, category).then(res => {
        const allGroups = res.data || []
        this.groups = allGroups.filter(
          (group) => (city === "全国" || group.city === city) && (category === "" || group.category_name === category)
        )

        console.log('当前城市:', city)
        console.log('当前分类:', category)
        console.log('筛选后的群组列表:', this.groups)
      }).catch((error) => {
        console.error('获取群组列表时出错:', error)
      }).finally(() => {
        this.isFetching = false
      })
    },
    
    joinGroup(groupId) {
      this.$router.push({ 
        name: 'group', 
        params: { id: groupId }, 
        query: {
          tabIndex: this.tabIndex,
          city: this.currentCity
        } 
      })
      console.log('传入的数据:', this.currentCity, this.tabIndex)
    },
  },

  computed: {  
    filteredGroups() {  
      const category = this.categories[this.tabIndex]?.name || "";  
      return this.groups.filter((group) => group.category_name === category);
    },
    isNoGroups() {
      // 当前分类下没有群组数据时，返回true
      return this.filteredGroups.length === 0;
    }
  },
}
</script>
  
<style scoped>
/*悬浮导航*/
.nut-drag :deep() .release-btn {
  background: #51CBDA;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  padding: 0;
}
.nut-drag :deep() .release-text {
  font-size: 16px;
}
</style>
  
<style scoped lang="scss">
@import "../styles/init.css";
.home-container {
  .header-container {
    background: #51CBDA;
    .flex-content.city {
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      padding-left: 12px;
      padding-top: 14px;
      padding-bottom: 15px;
    }
    .flex-content.city p {
      margin-right: 6px;
    }
    .nut-searchbar {
      padding-left: 12px;
      padding-right: 12px;
    }
    .nut-swiper-item {
      line-height: 150px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .main-container {
    overflow: hidden;
    .nut-tabpane {
      padding: 0 12px 50px 12px;
    }
    .no-group-message {
      padding: 12px;  
      text-align: center;  
      color: #999;  
    }
    .qun-list {
      display: flex;
      padding: 12px 0 0 0;
      .qun-img {
        width: 56px;
        height: 56px;
      }
      .qun-info {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .qun-title {
          font-size: 18px;
          font-weight: 700;
          width: 200px;
          white-space: nowrap;  /* 防止文本换行 */
          overflow: hidden;  /* 隐藏溢出的文本 */
          text-overflow: ellipsis;  /* 显示省略号 */
        }
        .release-time {
          display: flex;
          height: 20px;
          .clock-icon {
            margin-top: 1px;
            color: silver;
          }
          .clock-text {
            margin-left: 6px;
            color: silver;
          }    
        }
      }
      .clock-btn {
        margin-left: auto;
        margin-top: 10px; 
      }
      .nut-button--default {
        border: 0;
      }
    }
  }
}
</style>
<template>
  <div class="login-container">
    <nut-icon class="close-login" name="close" size="24px" color="#d6d6d6" @click="onCloseLogin"></nut-icon>
    <div class="login-form">
      <h1 class="login-title">欢迎使用</h1>
      <span class="login-slogan">快速加群&nbsp;&nbsp;资源对接</span>
      <nut-form>
        <nut-input class="input-phone" v-model="telephone" placeholder="手机号"/>
        <div class="code-group">
          <nut-input class="input-code" v-model="smscode" placeholder="验证码"/>
          <nut-button type="primary" shape="square" :disabled="smsCodeButtonDisabled" @click="sendSmsCode">{{ sendtext }}</nut-button>
        </div>
        <nut-button class="login-btn" type="primary" block :disabled="submitButtonDisabled" @click="onLogin">登录</nut-button>
        <nut-checkbox v-model="checkbox">已阅读并同意&nbsp;<a class="a-link" href="https://www.jd.com">用户服务协议</a>&nbsp;和&nbsp;
          <a class="a-link" href="https://www.jd.com">隐私政策</a></nut-checkbox>
      </nut-form>
    </div>
  </div>
</template>

<script>
import { Toast } from '@nutui/nutui'

export default {
  name: "LoginPage",
  data() {
    return {
      phoneRegex: /^1[3456789]\d{9}$/,
      smsCodeRegex: /^\d{6}$/,
      checkbox: false,

      telephone: '',
      smscode: '',
      sendtext: "发送验证码",
      timeout: 0,
    }
  },

  components: {},

  rules: {},

  computed: {
    smsCodeButtonDisabled() {
      return !this.telephone.match(this.phoneRegex) || this.timeout > 0;
    },

    submitButtonDisabled() {
      return !this.telephone.match(this.phoneRegex) || !this.smscode.match(this.smsCodeRegex);
    }
  },

  methods: {
    onCloseLogin() {
      this.$router.back()
    },

    sendSmsCode() {
      if (!this.telephone.match(this.phoneRegex)) {
        Toast.text("请输入有效的手机号");
        return;
      }
      
      const that = this
      this.$http.getSMSCode(this.telephone).then(res => {
        Toast.text("验证码发送成功")
        that.timeout = 60;
        const interval = setInterval(() => {
          that.timeout--;
          that.sendtext = that.timeout + "s后重新发送"
          if(that.timeout == 0){
            clearInterval(interval)
            that.sendtext = "发送验证码"
          }
        }, 1000);
      }).catch(error => {
        console.error("发送验证码失败", error)
        Toast.text("发送验证码失败: " + error.response.data.message)
      })
    },
    
    onLogin() {
      if(!this.checkbox) {
        Toast.text("请阅读并同意用户服务协议和隐私政策")
        return
      }
      
      this.$http.login({telephone: this.telephone,  smscode: this.smscode}).then(res => {
        const data = res.data
        const token = data.token
        const user = data.user
        this.$auth.setUserToken(user, token)

        // 登录成功后，跳转到首页
        const from = this.$route.query['from']
        if(from){
          this.$router.push(from)
        }else{
          this.$router.replace('/')
        }
      }).catch(error => {
        console.error("登录失败", error)
        Toast.text("登录失败: " + error.response.data.message)
      })
    },
  }
}
</script>

<style scoped lang="scss">
.login-container {
  display: flex;
  justify-content: center;  // 水平居中
  align-items: center;  // 垂直居中
  height: 90vh;
  position: relative;

  .close-login {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1000;
  }
  .login-form {
    width: 300px;
    .login-title {
      margin-bottom: 10px;
    }
    .login-slogan {
      display: block;
      margin-bottom: 40px;
      color: red;
    }
    .nut-input {
      padding: 8px 0 8px 0;
      margin-bottom: 10px;
      border-bottom: 1px solid #ececec;
    }
    .code-group {
      display: flex;
      flex-direction: row;  // 水平排列
      .input-code {
        width: 180px;
        margin: 0;
      }
      .nut-button {
        height: 41px;
        width: 120px;
        margin-left: 10px;
      }
      .nut-button--normal {
        padding: 0;
      }
    }
    .login-btn {
      margin-top: 20px;
      margin-bottom: 8px;
      font-size: 16px;
    }

    .nut-button {
      height: 46px;
    }
    .nut-checkbox :deep(.nut-checkbox__label) {
      margin-left: 10px;
    }
    .a-link {
      color: red;
    }
  }
}
</style>

import axios from "axios";
import auth from "./auth";
import router from "../router";

// const BASE_URL = 'http://127.0.0.1:8000'  // 开发环境
const BASE_URL = 'http://120.27.109.202:8000'  // 生产环境

class Http {
  constructor() {
    this.http = axios.create({
      baseURL: BASE_URL,
      timeout: 1000
    })

    // 请求之前的拦截器，用来设置JWT
    this.http.interceptors.request.use(config => {
      if (config.headers) {
        const token = auth.token
        if (token) {
          config.headers.Authorization = `JWT ${token}`
        }
      }
      return config
    })

    // 设置拦截器，如果返回403，跳转到登陆页面
    this.http.interceptors.response.use(
      response => response,
      err =>{
      if (err.response && err.response.status === 403) {
        auth.clearUserToken()
        router.replace('/login')
      }
      return Promise.reject(err)
    })
  }

  getSMSCode(telephone) {
    // const url = `/smscode?tel=${telephone}`
    return this.http.get(`/smscode?tel=${telephone}`)
  }

  login(data) {
    // const url = "/login"
    return this.http.post('/login', data)
  }

  getUser() {
    // const url = "/user"
    return this.http.post('/user')
  }

  // 获取分类数据
  getCategoryList() {
    // const url = "/category"
    return this.http.get('/category')
  }

  // 获取分类下的群组列表
  getGroupList() {
    // const url = "/groups"
    return this.http.get('/groups')
  }

  getGroups(id) {
    // const url = `/groups/${id}`
    return this.http.get(`/groups/${id}`)
  }

  addGroups(data){
    // const url = "/groups"
    return this.http.post('/groups', data)
  }

  updateGroups(id, data){
    // const url = "/groups/" + id 
    return this.http.put(`/groups/${id}`, data)
  }

  deleteGroups(id){
    // const url = "/groups/" + id
    return this.http.delete(`/groups/${id}`)
  }
}

export default new Http()
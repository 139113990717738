<template>
<div class="my-container">
  <div class="avatar-group">
    <nut-avatar class="avatar-icon" size="80" icon="my" @active-avatar="onAvatarClick"></nut-avatar>
    <div class="nickname-item">
      <div class="nickname" v-if="username">{{username}}</div>
      <div class="nickname" v-else @click="onLoginClick">未登录</div>
      <!-- <p>ID&nbsp;{{ uid }}</p> -->
    </div>
    <nut-icon class="icon-right" name="setting" size="24"></nut-icon>
  </div>

  <div class="member-group"></div>

  <div class="commission-group"></div>

  <div class="other-group">
    <div class="other-list">
      <div>发布的群</div>
      <nut-icon class="icon-right" name="rect-right" size="16"></nut-icon>
    </div>
    <div class="other-list">
      <div>订单</div>
      <nut-icon class="icon-right" name="rect-right" size="16"></nut-icon>
    </div>
    <div class="other-list">
      <div>政策条款</div>
      <nut-icon class="icon-right" name="rect-right" size="16"></nut-icon>
    </div>
    <div class="other-list">
      <div>版本号</div>
      <div class="icon-right" style="color: #acacac;">1.0.0</div>
    </div>
  </div>
</div>
</template>

<script type="text/ecmascript-6">
export default {
  name: "MyPage",
  data() {
    return {
      username: '',
    }
  },

  components: {},

  created() {  
    this.checkAuthStatus()
  },

  methods: {
    checkAuthStatus() {
      const Auth = this.$auth
      if (Auth && Auth.user) {
        // 用户已登录，获取用户名
        this.username = this.$auth.user.username
      }
    },

    onLoginClick() {
      this.$router.push('/login')
    },

    onAvatarClick() {
      
    }
  },
}
</script>

<style>
@import '/src/styles/init.css';

</style>

<style scoped lang="scss">
.icon-right {
  margin-left: auto;
}
.my-container {
  .avatar-group {
    display: flex;
    align-items: center;
    padding: 80px 20px 40px 20px;
    background: linear-gradient(to bottom, #51CBDA, rgba(255, 255, 255, 0));
    .nickname-item {
      margin-left: 12px;
      .nickname {
        font-size: 28px;
        font-weight: 500;
        margin-bottom: 8px;
      }
    }
  }
  .other-group {
    display: flex;
    flex-direction: column;
    .other-list {
      display: flex;
      align-items: center;
      padding: 16px 20px;
      border-bottom: 1px solid #ececec;
      font-size: 15px;
    }
  }
} 
</style>